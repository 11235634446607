namespace $ {

	$mol_test_mocks.push( $ => {
		$.$mol_log3_come = ()=> {}
		$.$mol_log3_done = ()=> {}
		$.$mol_log3_fail = ()=> {}
		$.$mol_log3_warn = ()=> {}
		$.$mol_log3_rise = ()=> {}
		$.$mol_log3_area = ()=> ()=> {}
	} )

}
